<template>
  <a-spin tip="Loading..." :spinning="loading">
    <div class="login"></div>
  </a-spin>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { iosLoginOrRegist } from '@/utils'

export default defineComponent({
  setup () {
    const loading = ref(true)

    return {
      loading
    }
  },

  created () {
    const code = this.$route.query.code
    if (code) {
      this.$store.dispatch('WxGZHRegist', code).then(() => {
        iosLoginOrRegist('/customer/regist')
        this.$message.success('登录成功')
        this.$router.push({ name: 'customer' })
      })
    } else {
      this.$store.dispatch('SnsapiUserinfo')
    }
  }
})
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
}
</style>
